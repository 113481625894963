<template>
  <el-select
    v-model="content"
    size="small"
    class="w-68 sm:text-sm text-xs"
    @change="change"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: 'QueryBarPageSize',
  props: {
    value: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      options: [
        {
          value: 10,
          label: '10'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 30,
          label: '30'
        }
      ],
      content: this.value
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  }
}
</script>
