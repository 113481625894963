<template>
  <div class="bg-blue-500 pb-16 sm:px-0 px-4">
    <div class="container pt-6 relative text-white text-sm">
      <div class="flex justify-between sm:items-center items-end">
        <div class="flex justify-center items-end">
          <div class="pr-3 sm:border-white sm:border-r">
            <img :src="logo">
          </div>
          <div
            class="ml-3 hidden sm:block"
          >
            TrueProfit Affiliate
          </div>
          {{ userMenuItems.length }}
        </div>
        <el-dropdown
          class="border-none focus:border-none"
          @command="handleClicked"
        >
          <div class="el-dropdown-link text-white sm:text-sm text-xs">
            <span>Wellcome, {{ name }}</span>
            <i class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(menuItem) in userMenuItems"
              :key="menuItem.id"
              :command="menuItem.command"
            >
              {{ menuItem.text }}
            </el-dropdown-item>
            <el-dropdown-item :command="handleLogout">
              Logout
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="text-center mt-16 text-2xl lg:text-4xl">
        TrueProfit Affiliate
      </div>
      <div class="absolute rounded -bottom-24 py-3 px-4 left-0 shadow-custom right-0 mx-auto bg-white flex justify-between items-center sm:w-10/12 w-full">
        <div class="truncate rounded bg-neutral-50 py-2 px-4 lg:w-10/12 w-8/10 text-black">
          {{ link }}
        </div>
        <button
          v-clipboard:copy="link"
          v-clipboard:success="onCopy"
          class="ml-2 rounded hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 min-w-100 lg:text-sm text-xs"
        >
          Copy Link
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'AffiliateHeader',
  data () {
    return {
      logo: require('@/assets/images/logo.svg')
    }
  },
  computed: {
    ...mapState('partner/fetchReferrals', ['name', 'link']),
    ...mapState('ui', ['userMenuItems'])
  },
  methods: {
    ...mapActions('auth/account', ['logout']),
    onCopy (e) {
      this.$notify({
        customClass: 'notify',
        title: 'Notification',
        message: 'Link copied successfully',
        type: 'success'
      })
    },
    handleClicked (func) {
      if (typeof func === 'function') {
        func()
      }
    },
    handleLogout () {
      this.$confirm('Are you sure you want to logout?', 'Logout confirm', {
        confirmButtonText: 'Yes',
        customClass: 'message-box',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(async () => {
        this.logout()
      }).catch(action => {})
    }
  }
}
</script>
