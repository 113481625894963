<template>
  <div class="min-h-screen">
    <div class="none-footer">
      <Header />
      <main class="pt-20 pb-10">
        <PanelGroup />
        <div class="container sm:px-0 px-4">
          <p class="text-lg font-medium leading-6 mt-16">
            Referrals List
          </p>
          <table class="table-auto w-full mt-8 text-left text-sm">
            <thead>
              <tr class="font-medium border-b border-neutral-100">
                <th class="py-3">
                  Shop Name
                </th>
                <th class="py-3">
                  Price
                </th>
                <th class="py-3">
                  Your Share
                </th>
                <th class="py-3 hidden sm:table-cell">
                  Trial Days
                </th>
                <th class="py-3 hidden sm:table-cell">
                  Date Installed
                </th>
                <th class="py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in referrals"
                :key="index"
                class="bg-gray-100"
              >
                <td :class="rowClasses">
                  {{ item.myshopify_domain | formatShopName }}
                </td>
                <td :class="rowClasses">
                  ${{ item.price }}
                </td>
                <td :class="rowClasses">
                  ${{ item.earned }}
                </td>
                <td
                  class="hidden sm:table-cell"
                  :class="rowClasses"
                >
                  {{ item.trial_days }} Days
                </td>
                <td
                  class="hidden sm:table-cell"
                  :class="rowClasses"
                >
                  {{ item.installed_at | formatDate }}
                </td>
                <td :class="[rowClasses, 'capitalize', item.status === 'uninstalled' ? 'text-red-500': 'text-green-500']">
                  {{ item.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-end items-center mt-4">
            <div class="flex justify-center items-center">
              <p class="mr-4 text-sm text-neutral-700">
                Rows per page
              </p>
              <QueryBarPageSize
                v-model="query.show_posts"
                @input="changeShowPost"
              />
            </div>
            <el-pagination
              :page-size="query.show_posts"
              layout="prev, pager, next"
              :total="totalPages"
              :current-page.sync="query.page"
              @current-change="changePage"
            />
          </div>
        </div>
      </main>
    </div>
    <Footer />
    <DialogChangePassword />
  </div>
</template>

<script>
import Header from '@/components/Header'
import PanelGroup from '@/components/PanelGroup'
import Footer from '@/components/Footer'
import QueryBarPageSize from '@/components/QueryBarPageSize'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'TrueAffiliate',
  components: {
    Header,
    PanelGroup,
    Footer,
    QueryBarPageSize
  },
  data () {
    return {
      query: {
        token: '',
        page: 1,
        show_posts: 10
      }
    }
  },
  computed: {
    ...mapState('auth/token', ['token']),
    ...mapState('partner/fetchReferrals', ['referrals', 'totalPages']),
    rowClasses () {
      return {
        'border-b border-neutral-100 py-3': true
      }
    }
  },
  async created () {
    this.query.token = this.token
    await this.getInsights()
  },
  methods: {
    ...mapActions('partner/fetchReferrals', ['fetchReferrals']),
    ...mapActions('partner/fetchTransactions', ['fetchTransactions']),
    ...mapMutations('auth/token', ['SET_TOKEN']),
    async getInsights () {
      await Promise.all([
        this.fetchReferrals(this.query),
        this.fetchTransactions()
      ])
    },
    async changeShowPost () {
      await this.fetchReferrals(this.query)
    },
    async changePage () {
      await this.fetchReferrals(this.query)
    }
  }
}
</script>
