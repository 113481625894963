<template>
  <div class="container sm:px-0 px-4 text-left sm:text-sm text-xs text-black border-t border-neutral-200 py-4">
    Copyright © 2021 TrueProfit Affiliate <span class="hidden sm:inline">| Powered by Golden Cloud</span>
  </div>
</template>

<script>
export default {
  name: 'AffiliateFooter'
}
</script>
