<template>
  <div class="shadow-custom text-center py-4 border border-neutral-50">
    <p class="mb-2 text-sm">
      {{ title }}
    </p>
    <p class="lg:text-2xl text-xl">
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>
