<template>
  <div class="container sm:px-0 px-4">
    <div class="grid gap-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-flow-row">
      <Panel
        title="Total Active Referrals"
        :value="report.totalActive"
      />
      <Panel
        title="Total Click"
        :value="report.totalClick"
      />
      <Panel
        title="Pending Payout"
        :value="transaction.pendingCommission"
      />
      <Panel
        title="Total Commission"
        :value="transaction.totalCommission"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Panel from './Panel'
export default {
  name: 'PanelGroup',
  components: {
    Panel
  },
  computed: {
    ...mapState('partner/fetchReferrals', ['report']),
    ...mapState('partner/fetchTransactions', ['transaction'])
  }
}
</script>
